.hero-section {
    background-color: #0e1a1a;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  
  h1 .star-icon {
    font-size: 2.5rem;
    vertical-align: middle;
  }
  
  .image-placeholder {
    width: 80%;
    height: 100px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    background-size: cover;
    background-position: center;
  }
  
  .scroll-icon {
    font-size: 1.2rem;
    color: #fff;
  }
  
  .btn:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  